import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Store/Store";

export const UserGuard = (props: any) => {
  const { children } = props;
  const auth = useSelector((state: RootState) => state?.auth);
  const [checked, setChecked] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (!auth.isAuthenticated) {
      setChecked(true);
    } else {
      router.push("/dashboard");
    }
  }, [router.isReady]);

  if (!checked) {
    return null;
  }

  return children;
};

UserGuard.defaultProps = {
  children: undefined,
};
